<template>
  <div>
    <a-card :bordered="false" class="content" style="width: 100%">
      <a-table
        ref="table"
        style="width: 100%; margin-top: 10px"
        size="small"
        :dataSource="data"
        :columns="columns"
      >
      </a-table>
    </a-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: [],
      columns: [],
    };
  },
  methods: {},
  mounted() {},
};
</script>
