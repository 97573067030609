<template>
  <div>
    <a-card :bordered="false" class="content" style="width: 100%">
      <div
        style="
          text-align: center;
          font-size: 20px;
          font-weight: 900;
          margin-right: 10%;
        "
      >
        个人中心
      </div>
      <a-form-model
        ref="userForm"
        :model="info"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        style="margin-top: 50px"
      >
        <a-form-model-item label="用户名" prop="username">
          <a-input v-model="info.username" placeholder="用户名" disabled />
        </a-form-model-item>
        <a-form-model-item label="邮箱" prop="email">
          <a-input v-model="info.email" placeholder="邮箱" disabled />
        </a-form-model-item>
        <a-form-model-item label="手机号" prop="phone">
          <a-input v-model="info.phone" placeholder="手机号" />
        </a-form-model-item>
        <a-form-model-item label="上次登录" prop="last_login">
          <a-input v-model="info.last_login" placeholder="上次登录" disabled />
        </a-form-model-item>
        <a-form-model-item label="加入时间" prop="date_join">
          <a-input v-model="info.date_join" placeholder="加入时间" disabled />
        </a-form-model-item>
        <a-form-model-item label="身高(cm)" prop="height">
          <a-input-number
            v-model="info.height"
            :min="0"
            :max="300"
            :step="0.01"
          />
        </a-form-model-item>
        <a-form-model-item label="体重(kg)" prop="weight">
          <a-input-number
            v-model="info.weight"
            :min="0"
            :max="300"
            :step="0.01"
          />
        </a-form-model-item>
        <a-form-model-item label="性别" prop="sex">
          <a-radio-group name="sex" ref="sex" v-model="info.sex">
            <a-radio :value="0">男</a-radio>
            <a-radio :value="1">女</a-radio>
          </a-radio-group>
        </a-form-model-item>
      </a-form-model>
      <div style="text-align: center; margin-right: 10%">
        <a-button type="primary" @click="submitInfo">修改信息</a-button>
      </div>
    </a-card>
  </div>
</template>

<script>
import { userHome, changeUserInfo } from "@/api/auth";
import { changeTime } from "@/libs/utils";
export default {
  data() {
    return {
      info: {
        username: "",
        email: "",
        phone: "",
        last_login: "",
        date_join: "",
        height: null,
        weight: null,
        sex: null,
      },
      labelCol: { span: 6 },
      wrapperCol: { span: 10 },
    };
  },
  methods: {
    submitInfo() {
      var temp_data = {
        phone: this.info.phone,
        height: this.info.height,
        weight: this.info.weight,
        sex: this.info.sex,
      };
      console.log(temp_data);
      changeUserInfo(temp_data)
        .then((res) => {
          var data = res.data;
          if (data.status === 0) {
            this.$message.error(data.message);
          } else {
            this.$message.success(data.message);
          }
        })
        .catch((err) => {
          this.$message.error(err.toString());
        });
    },
    getUserHome() {
      userHome()
        .then((res) => {
          let data = res.data;
          this.info = data;
          this.info.date_join = changeTime(this.info.date_join);
          this.info.last_login = changeTime(this.info.last_login);
        })
        .catch((err) => {
          this.$message.error(err.toString());
        });
    },
  },
  mounted() {
    this.getUserHome();
  },
};
</script>
